<template>
  <div class="card">
    <div class="card-header">
      <div>
        <div class="card-title">
          {{ $t('page.title_generator.page-title') }}
        </div>
        <small>
          {{ $t('page.title_generator.page-subtitle') }}
        </small>
      </div>
    </div>
    <div class="card-body">
      <div
        class="grid grid-cols-1 divide-y divide-gray-300"
        v-if="headlines.length"
      >
        <div
          class="py-2 px-4 flex justify-between items-start"
          v-for="(headline, index) in headlines"
          :key="index"
        >
          <div
            v-html="headline"
          />
          <div class="flex flex-col lg:flex-row ml-4 space-y-2 lg:space-y-0 lg:space-x-4">
            <a
              class="cursor-pointer"
              @click="handleCopy(index)"
              :title="$t('action.copy')"
              v-if="isCopySupported"
            >
              <i class="fas fa-copy"></i>
            </a>
            <a
              class="cursor-pointer"
              @click="removeTitle(index)"
              :title="$t('action.delete')"
            >
              <i class="fa fa-trash"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useToast } from '@/composables/useToast'
import { useClipboard } from '@vueuse/core'
import { defineComponent, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTitleGenerator } from '@/composables/tools/useTitleGenerator'

export default defineComponent({
  setup () {
    const { t } = useI18n()

    const {
      titles: headlines,
      removeTitle
    } = useTitleGenerator()

    const {
      copy,
      isSupported: isCopySupported
    } = useClipboard()

    const handleCopy = (index: number) => {
      const sentence = unref(headlines)[index].replace(/(<([^>]+)>)/gi, "")
      copy(sentence)
      useToast.fire({
        icon: 'success',
        title: t('state.copy_to_clipboard.title'),
        text: t('state.copy_to_clipboard.message')
      })
    }

    return {
      headlines,
      isCopySupported,
      handleCopy,
      removeTitle
    }
  }
})
</script>

<style scoped>
i {
  @apply text-gray-600 !important;
}
i.fa-trash {
  @apply text-red-700 !important;
}
</style>
