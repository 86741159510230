<template>
  <div
    class="grid grid-cols-1 divide-y divide-gray-300"
  >
    <div
      class="py-2 flex justify-between items-start"
      v-for="(link, index) in links"
      :key="link.phone"
    >
      <div>
        <div class="font-semibold">
          {{ link.phone }}
        </div>
        <div
          class="text-gray-600 text-sm mt-1"
          v-if="link.message"
        >
          {{ link.message }}
        </div>
      </div>
      <div class="flex flex-col lg:flex-row ml-4 space-y-2 lg:space-y-0 lg:space-x-4">
        <a
          class="cursor-pointer"
          @click="handleCopy(index)"
          :title="$t('action.copy')"
          v-if="isCopySupported"
        >
          <i class="fas fa-copy"></i>
        </a>
        <a
          class="cursor-pointer"
          @click="remove(index)"
          :title="$t('action.delete')"
          v-if="canRemove"
        >
          <i class="fa fa-trash"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useToast } from '@/composables/useToast'
import { useClipboard } from '@vueuse/core'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    links: {
      type: Array,
      default: () => ([])
    },
    canRemove: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'remove'
  ],

  setup (props, { emit }) {
    const { t } = useI18n()

    const {
      copy,
      isSupported: isCopySupported
    } = useClipboard()

    const handleCopy = (index: number) => {
      const whatsappLink = props.links[index] as { link: string }
      copy(whatsappLink.link)
      useToast.fire({
        icon: 'success',
        title: t('state.copy_to_clipboard.title'),
        text: t('state.copy_to_clipboard.message')
      })
    }

    const remove = async (index: number) => {
      emit('remove', index)
    }
    
    return {
      isCopySupported,
      handleCopy,
      remove
    }
  }
})
</script>

<style scoped>
i {
  --tw-text-opacity: 1 !important;
  color: rgba(82, 82, 91, var(--tw-text-opacity)) !important;
}
i.fa-trash {
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}
</style>